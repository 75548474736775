<template>
  <div>
    <router-link :to="{ name: 'admin.announcements.create-sms' }" class="btn btn-theme btn-sm float-end"><i class="lni lni-plus me-2"></i>Send SMS</router-link>
    <router-link :to="{ name: 'admin.announcements.create-email' }" class="btn btn-theme btn-sm float-end"><i class="lni lni-plus me-2"></i>Send Email</router-link>
    <h3>Announcements</h3>
    <loading v-if="loading" />
    <div class="card mt-3" v-if="! loading">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-5">
            <form @submit.prevent="fetch">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Email"
                  aria-label="Search Email"
                  aria-describedby="button-addon2"
                  v-model="search_term"
                />
                <button
                  class="btn btn-outline-secondary border"
                  type="submit"
                  id="button-addon2"
                >
                  <i class="lni lni-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Method</th>
              <th>Type</th>
              <th>Recipients</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="announcements.length == 0">
              <td colspan="6">There is no data to display</td>
            </tr>
            <tr v-for="(announcement, i) in announcements" :key="`announcement-${i}`">
              <td>{{ announcement.method }}</td>
              <td>{{ announcement.type }}</td>
              <td>{{ announcement.recipients }}</td>
              <td>{{ announcement.date }}</td>
              <td>
                <router-link :to="{ name: 'admin.announcements.show', params: { id: announcement.id }}" class="btn btn-sm btn-info"><i class="lni lni-eye me-2"></i> Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="fetch" :perPage="10" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: false,
      announcements: [],
      search_term: '',
      pagination: { page: 1, records: 1 }
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/announcements?page=${this.pagination.page}&search_term=${this.search_term}`).then(response => {
        this.announcements = response.data.announcements
        this.pagination = response.data.pagination
        this.loading = false
      })
    }
  }
}
</script>